import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">Contáctame</h2>

      <h2 className="title">Ponerse en contacto</h2>

      <p>
      Mi bandeja de entrada siempre está abierta para ti. Si necesitas ayuda con alguno de los sistemas que comparto en mis videos, contáctame por Instagram. También estoy disponible para proyectos de desarrollo más complejos. ¡Espero tu mensaje!
      </p>

      <a target='_blanck' className="email-link" href={`https://www.instagram.com/heavydeveloper/`}>
       Escribeme
      </a>
    </StyledContactSection>
  );
};

export default Contact;
